<template>
  <span class="regular-12 text-black text-start">
    <router-link
      class="btn btn-secondary btn-sm"
      :to="`/products/${main ?? id ?? modelValue}/forecast/${
        main ? id : variantId > 0 ? variantId : ''
      }`"
    >
      <BaseIcon name="barCart" color="#4B506D" />
    </router-link>
  </span>
</template>

<script>
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));
export default {
  name: "Forecast",
  components: { BaseIcon },
  props: {
    id: Number,
    main: Number,
    type: String,
    row: Object,
    modelValue: String,
    variantId: Number,
  },
  data() {
    return {
      show: false,
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
